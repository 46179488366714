"use client";
import "./Dashboard.css";
import Navbar from "../NavbarProfile/NavbarProfile";
import { Form, Pagination, Table } from "react-bootstrap";
import Sidebar from "../SidebarNew/Sidebar";
import Fillbutton from "../FillButtonNew/Fillbutton";
import { Arrowsvg } from "../../../public/svgs/svg";
import { Link } from "react-router-dom";
import platformPackages from "./platformPackages.json";
import Instance from "../../Utils/Axios";
import { useState, useEffect } from "react";
import { showToast } from "../../Utils";
import { useNavigate } from "react-router-dom";
import { color } from "@mui/system";
import { formatDistanceToNow, format, parse as dateParse } from "date-fns";
import parse from "html-react-parser";

export default function Dashboard2() {
  const activeSide = 1;
  const [error, setError] = useState("");
  const [profileData, setProfileData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [packageData, setPackageData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [prData, setPrData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [numberInput, setNumberInput] = useState("");
  const [tempInput, setTempInput] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchProfileData = () => {
  //     const token = localStorage.getItem("tokenId");
  //     Instance.post("/user/profile", {
  //       tokenId: token,
  //     })
  //       .then((response) => {
  //         setProfileData(response.data.userProfile);
  //         setError("");
  //       })
  //       .catch((error) => {
  //         setError("Cannot fetch the data");
  //         console.error("Cannot fetch the profile data", error);
  //       });
  //   };
  //   fetchProfileData();
  // }, []);

  useEffect(() => {
    const fetchDashboardData = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/dashboard", {
        tokenId: token,
      })
        .then((response) => {
          console.log(response.data.message);
          setDashboardData(response.data.message.package.data);
          setPackageData(response.data.message);
          setNotificationData(response.data.message.pressRelease.notification);
          setError("");
        })
        .catch((error) => {
          setError("Cannot fetch the data");
          console.error("Cannot fetch the dashboard data", error);
        });
    };
    fetchDashboardData();
  }, []);

  const formatDate = (dateString) => {
    try {
      const date = parse(dateString, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(date, "dd MMM yyyy");
    } catch (error) {
      console.error("Invalid date format", error);
      return "Invalid date";
    }
  };

  const timeAgo = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  useEffect(() => {
    const fetchPRList = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/pressrelease/list", {
        tokenId: token,
      })
        .then((response) => {
          const data = response.data?.message || [];
          setPrData(Array.isArray(data) ? data : []);
        })
        .catch((error) => {
          console.error("failed to fetch card details", error);
        });
    };
    fetchPRList();
  }, []);

  const purchaseCredits = (packageId) => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/credit/buyCredits", {
      tokenId: token,
      packageId: packageId,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          const superPNR = response.data.superPNR;
          localStorage.setItem("superPNR", superPNR);

          const url = `/user/pricing/${packageId}`;
          window.open(url, "_blank");
        }
      })
      .catch((error) => {
        console.error("cannot procced ahead!", error);
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleInputChange = (e) => {
    setTempInput(e.target.value);
  };

  const handleInputSave = () => {
    const pageNumber = parseInt(tempInput, 10);
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setNumberInput(tempInput);
      setCurrentPage(pageNumber);
    } else {
      showToast("Invalid page number", "error", "error");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleInputSave();
    }
  };

  const handleRowsPerPageChange = (e) => {
    const rows = parseInt(e.target.value, 10);
    setRowsPerPage(rows);
    setCurrentPage(1);
  };

  const indexOfLastData = currentPage * rowsPerPage;
  const indexOfFirstData = indexOfLastData - rowsPerPage;
  const currentData = dashboardData.slice(indexOfFirstData, indexOfLastData);

  const totalPages = Math.ceil(dashboardData.length / rowsPerPage);

  const renderPaginationItems = () => {
    const items = [];

    // Always show the first page
    if (currentPage > 3) {
      items.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );
      items.push(<Pagination.Ellipsis key="start-ellipsis" disabled />);
    }

    // Show the current page, and the two pages before and after it
    for (
      let page = Math.max(1, currentPage - 1);
      page <= Math.min(totalPages, currentPage + 1);
      page++
    ) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    // Show the last page and ellipsis if needed
    if (currentPage < totalPages - 2) {
      items.push(<Pagination.Ellipsis key="end-ellipsis" disabled />);
      items.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <div className="dashboard_container row gx-0">
      <Sidebar activeSide={activeSide} />
      <div className={`main col`}>
        <Navbar name={"Dashboard"} />
        <div className={`row gx-0`}>
          <div className={` left_main col-12 col-md-8`}>
            <div className="package_info">
              <div className="articles_info">
                <img
                  className={"d_article_img mb-0"}
                  src="/build/img/Newspaper.svg"
                  alt="zex pr wire Logo"
                  width={48}
                  height={48}
                  priority
                />
                <p className="d_article_num ">
                  {packageData.pressRelease?.publishedCount || 0}
                </p>
                <p className="d_article_text mb-0">Articles Published</p>
              </div>
              <div className="package_owned">
                <img
                  className={"d_article_img mb-0"}
                  src="/build/img/Package.svg"
                  alt="zex pr wire Logo"
                  width={48}
                  height={48}
                  priority
                />
                <p className="d_article_num ">
                  {" "}
                  {packageData.package?.activePackageCount || 0}
                </p>
                <p className="d_article_text mb-0">Packages Owned</p>
              </div>
              <div className="pr_status_container">
                {dashboardData.length > 0 ? (
                  <>
                    <div className="dash_expire_info ">
                      {dashboardData.slice(0, 2).map((pd, index) => (
                        <div className="dash_expire_child" key={index}>
                          <h5>{pd.name}</h5>
                          <p className="dash_expire_title mb-0">
                            Package expiring on
                          </p>
                          <p className="dash_expire_date mb-0">
                            {formatDate(pd.expiry)}
                          </p>
                          <p className="dash_expire_time mb-0">
                            Expiring {timeAgo(pd.expiry)}
                          </p>
                        </div>
                      ))}
                      {/* <div className="dash_expire_child">
                        <p className="dash_expire_title mb-0">
                          Package expiring on
                        </p>
                        <p className="dash_expire_date mb-0">12 Mar 2024</p>
                        <p className="dash_expire_time mb-0">
                          About to expire in a 3 days
                        </p>
                      </div>
                      <div className="dash_expire_child">
                        <p className="dash_expire_title mb-0">
                          Package expiring on
                        </p>
                        <p className="dash_expire_date mb-0">16 Mar 2024</p>
                        <p className="dash_expire_time mb-0">
                          About to expire in a week
                        </p>
                      </div> */}
                    </div>
                    {/* <div className="d-flex justify-content-between pr_text_wrapper">
                      <p className="dash_prstatus_text mb-0">PR Status</p>
                      <div className="dash_viewall mb-0">View all</div>
                    </div> */}
                  </>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center m-auto"
                    style={{ height: "100%" }}
                  >
                    <div>
                      <p className="first_package_text mb-3">
                        Let’s buy your first package
                      </p>
                      <Link to="/user/pricing">
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto first_pack_btn"
                        />
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="package_details_text">
              <p className="dash_pack_text mb-0">Platform Packages</p>
              <p className="dash_pack_view mb-0">View all</p>
            </div>
            <div className="table-responsive">
              <Table className="table_container">
                <thead className="t_head_wrapper">
                  <tr className="t_head_row">
                    <th className="t_head_item">Name</th>
                    <th className="t_head_item">Price</th>
                    <th className="t_head_item">PR count</th>
                    <th className="t_head_item">Validity</th>
                    <th className="t_head_item">Type</th>
                    <th className="t_head_item">Sub type</th>
                    <th className="t_head_item">Buy</th>
                  </tr>
                </thead>
                <tbody>
                  {platformPackages.map((pk, index) => (
                    <tr className="align-middle">
                      <td className="t_item">{pk.name}</td>
                      <td className="t_item">$ {pk.price}</td>

                      <td className="t_item">{pk.pressReleaseCount}</td>
                      <td className="t_item">{pk.validity} days</td>
                      <td className="t_item">{pk.type}</td>
                      <td className="t_item">{pk.subType}</td>
                      <td
                        onClick={() => {
                          purchaseCredits(pk);
                        }}
                      >
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="table_footer">
                <div className="rows_per_page">
                  <p className="mb-0">Rows per page</p>
                  <Form.Select
                    aria-label="Default select example"
                    bsPrefix="dash_select"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="1">4</option>
                    <option value="2">5</option>
                    <option value="3">6</option>
                    <option value="1">7</option>
                    <option value="2">8</option>
                    <option value="3">9</option>
                    <option selected>10</option>
                  </Form.Select>
                </div>
                <div className="rows_page">
                  <Pagination
                    className="mb-0 ps-0 "
                    bsPrefix="custom_pagination"
                  >
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item className="d-none d-lg-block">
                      {3}
                    </Pagination.Item>
                    <Pagination.Ellipsis />

                    <Pagination.Item className="d-none d-lg-block">
                      {10}
                    </Pagination.Item>
                    <Pagination.Item>{11}</Pagination.Item>
                    <Pagination.Item>{12}</Pagination.Item>
                  </Pagination>
                </div>
                <div className="go_to_page">
                  <p className="mb-0">Go to page</p>
                  <input />
                  <Arrowsvg rotate="0deg" />
                </div>
              </div>
            </div> */}

            <div className="package_details_text">
              <p className="dash_pack_text mb-0">Package Details</p>
              <p className="dash_pack_view mb-0">View all</p>
            </div>
            <div className="table-responsive">
              <Table className="table_container">
                <thead className="t_head_wrapper">
                  <tr className="t_head_row">
                    <th className="t_head_item">NAME</th>
                    <th className="t_head_item">PR LIMIT</th>
                    <th className="t_head_item">USER PR</th>
                    <th className="t_head_item">CREDITS LEFT</th>
                    <th className="t_head_item">EXPIRY</th>
                    <th className="t_head_item">BUY AGAIN</th>
                  </tr>
                </thead>
                {true ? (
                  <tbody>
                    {currentData.map((db, index) => (
                      <tr className="align-middle" key={index}>
                        <td className="t_item">{db.name}</td>
                        <td className="t_item">{db.prLimit}</td>
                        <td className="t_item">{db.usedPR}</td>
                        <td className="t_item">0</td>
                        <td className="t_item">{db.expiry}</td>
                        <td
                          onClick={() => {
                            purchaseCredits(db.packageId);
                          }}
                        >
                          <Fillbutton
                            text="Buy Now"
                            className="mx-auto small_btn"
                          />
                        </td>
                      </tr>
                    ))}
                    {/* <tr className="align-middle">
                      <td className="t_item">Boomerang</td>
                      <td className="t_item">11</td>
                      <td className="t_item">11</td>
                      <td className="t_item">0</td>
                      <td className="t_item">Aug 27,2019</td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Coin Market Cap</td>
                      <td className="t_item">5</td>
                      <td className="t_item">5</td>
                      <td className="t_item">0</td>
                      <td className="t_item">Mar 02, 2019</td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Yahoo Finance</td>
                      <td className="t_item">3</td>
                      <td className="t_item">0</td>
                      <td className="t_item">3</td>
                      <td className="t_item">Jun 24, 2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">AP News</td>
                      <td className="t_item">4</td>
                      <td className="t_item">4</td>
                      <td className="t_item">2</td>
                      <td className="t_item">Dec 18, 2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Hackernoon</td>
                      <td className="t_item">11</td>
                      <td className="t_item">6</td>
                      <td className="t_item">5</td>
                      <td className="t_item">Aug 27,2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Coin Desk</td>
                      <td className="t_item">5</td>
                      <td className="t_item">5</td>
                      <td className="t_item">0</td>
                      <td className="t_item">Mar 02, 2019</td>
                      <td>
                        {" "}
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">CoinTelegraph</td>
                      <td className="t_item">3</td>
                      <td className="t_item">0</td>
                      <td className="t_item">3</td>
                      <td className="t_item">Jun 24, 2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Bitcoin.com</td>
                      <td className="t_item">4</td>
                      <td className="t_item">2</td>
                      <td className="t_item">2</td>
                      <td className="t_item">Dec 18, 2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">News BTC</td>
                      <td className="t_item">3</td>
                      <td className="t_item">0</td>
                      <td className="t_item">3</td>
                      <td className="t_item">Jun 24, 2024</td>
                      <td></td>
                    </tr>
                    <tr className="align-middle">
                      <td className="t_item">Binance Feed</td>
                      <td className="t_item">4</td>
                      <td className="t_item">2</td>
                      <td className="t_item">2</td>
                      <td className="t_item">Dec 18, 2024</td>
                      <td></td>
                    </tr> */}
                  </tbody>
                ) : (
                  <tbody>
                    <tr className=" ">
                      <td className="px-3 pt-3" colSpan={5}>
                        Buy your first PR package
                      </td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              <div className="table_footer">
                <div className="rows_per_page">
                  <p className="mb-0">Rows per page</p>
                  {/* <Form.Select
                    aria-label="Default select example"
                    bsPrefix="dash_select"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="1">4</option>
                    <option value="2">5</option>
                    <option value="3">6</option>
                    <option value="1">7</option>
                    <option value="2">8</option>
                    <option value="3">9</option>
                    <option selected>10</option>
                  </Form.Select> */}

                  <Form.Select
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    {[5, 10, 15, 20].map((rows) => (
                      <option value={rows} key={rows}>
                        {rows}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="rows_page">
                  <Pagination
                    className="mb-0 ps-0 "
                    bsPrefix="custom_pagination"
                  >
                    {/* <Pagination.First onClick={() => handlePageChange(1)} /> */}
                    <Pagination.Prev
                      onClick={() =>
                        handlePageChange(Math.max(1, currentPage - 1))
                      }
                    />
                    {/* {[...Array(totalPages).keys()].map((page) => (
                      <Pagination.Item
                        key={page + 1}
                        active={page + 1 === currentPage}
                        onClick={() => handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </Pagination.Item>
                    ))} */}
                    {renderPaginationItems()}
                    <Pagination.Next
                      onClick={() =>
                        handlePageChange(Math.min(totalPages, currentPage + 1))
                      }
                    />
                    {/* <Pagination.Last
                        onClick={() => handlePageChange(totalPages)}
                      /> */}
                  </Pagination>
                </div>
                <div className="go_to_page">
                  <p className="mb-0">Go to page</p>
                  <input
                    value={tempInput}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                  />
                  <Arrowsvg rotate="0deg" onClick={handleInputSave} />
                </div>
              </div>
            </div>
          </div>
          <div className={`col-12  col-md-4 right_main`}>
            <p className="mb-0 right_main_title">My Last Pr</p>
            {prData.length > 0 ? (
              <div className="lastpr_main_cont">
                {prData.slice(-5).map((data, index) => (
                  <div
                    className="d-flex gap-2"
                    key={index}
                    onClick={() => {
                      navigate("/user/pr-manager");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      className={""}
                      src="/build/img/notify.svg"
                      alt="zex pr wire Logo"
                      width={48}
                      height={48}
                      priority
                    />
                    <div className="lastpr_info">
                      <p className="lastpr_text">{data.title}</p>
                      <p className="lastpr_time mb-0">{data.createdOn}</p>
                    </div>
                  </div>
                ))}
                {/* <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      World Blockchain Summit Set the Stage for a Decentralized
                      Future with Ground-breaking Insights and Collaborations
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      ZEX PR WIRE Join Forces with Blockchain Life to be the
                      Official PR Partner for the 10th Edition of Blockchain
                      Life 2023 in Dubai
                    </p>
                    <p className="lastpr_time mb-0 ">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      UAE’s Future Blockchain Summit to address all things
                      Blockchain in Dubai from tomorrow
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      ZEX PR WIRE World Best PR NewsWire now joins Token2049 To
                      Exhibit at  Singapore Edition Sept 2023
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      Middle East & North Africa’s Fintech community to gather
                      tomorrow at Fintech Surge 2022
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div> */}
              </div>
            ) : (
              <div className="d-flex justify-content-center my-4">
                <div>
                  <p className="submit_first_pr mb-3">
                    Let’s Submit your first PR
                  </p>
                  <Link to="/user/submit-pr">
                    <Fillbutton
                      text="Submit Now"
                      className="mx-auto first_pack_btn"
                    />
                  </Link>
                </div>
              </div>
            )}
            <div className="notification_info">
              <div className=" notification_title">
                <p className="notification_text">Notification</p>
                <p className="notification_viewall">
                  <Link to="/user/notification">View all</Link>
                </p>
              </div>
              {notificationData.length > 0 ? (
                <div>
                  {notificationData
                    .slice(-2)
                    .reverse()
                    .map((notification, index) => (
                      <div className="notification_actions" key={index}>
                        <div
                          className="color_box"
                          style={{
                            backgroundColor:
                              notification.status === "PUBLISHED"
                                ? "#5DCC5C"
                                : notification.status === "PENDING"
                                ? "#fff"
                                : notification.status === "OPEN"
                                ? "#f66c1f"
                                : notification.status === "REJECTED"
                                ? ""
                                : "#000",
                          }}
                        ></div>
                        <div className="notification_actions_info">
                          <p className="notification_action mb-2">
                            PR ID: {notification.id} - {notification.status}
                          </p>
                          <p className="notification_action_desc mb-2">
                            {parse(notification.description)}
                          </p>
                          <p className="notification_action_time mb-4">
                            {timeAgo(notification.updatedOn)}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="d-flex gap-2">
                  <div
                    className="color_box"
                    style={{ backgroundColor: "#595FDE" }}
                  ></div>
                  <div className="notification_actions_info">
                    <p className="notification_action">No Notifications yet</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
