import {
  Affiliatesvg,
  Companysvg,
  Conatactussvg,
  Dashboardsvg,
  Gallerysvg,
  Newsroomsvg,
  PaymentHistorysvg,
  Pricingsvg,
  Prmanage,
  Supportsvg,
} from "../../../public/svgs/svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css";

export default function Sidebar({ activeSide }) {
  const [activeTab, setActiveTab] = useState(activeSide);
  const [showFullSidebar, setShowFullSidebar] = useState(false);
  return (
    <sidebar
      className="sidebar col"
      style={{ backgroundImage: "url('/img/sidebarLines.svg')" }}
    >
      <Link to="/user/dashboard">
        <div className={`d-none d-lg-flex logo_wrapper`}>
          <img
            className={""}
            src="/build/img/imglogowhite.svg"
            alt="zex pr wire Logo"
            width={110}
            height={50}
          />
        </div>
      </Link>
      <div
        className={` d-lg-none logo_wrapper cursor-pointer`}
        style={{ cursor: "pointer" }}
        onClick={() => setShowFullSidebar(!showFullSidebar)}
      >
        <img
          className={""}
          src="/build/img/imglogowhite.svg"
          alt="zex pr wire Logo"
          width={showFullSidebar ? 110 : 50}
          height={showFullSidebar ? 50 : 30}
          priority
        />
      </div>
      <ul className={`ps-0 nav_items_container`}>
        <li
          className={`cursor-pointer nav_item${
            activeTab === 1 ? "_active" : ""
          }`}
          onClick={() => setActiveTab(1)}
        >
          <Link to="/user/dashboard">
            <Dashboardsvg fill={`${activeTab === 1 ? "#FFFFFF" : "#8B8EA5"}`} />
          </Link>
          <Link
            to="/user/dashboard"
            className={`${showFullSidebar ? "" : "d-none"} d-lg-inline`}
          >
            Dashboard
          </Link>
        </li>
        <li
          className={`cursor-pointer nav_item${
            activeTab === 2 ? "_active" : ""
          }`}
          onClick={() => setActiveTab(2)}
        >
          <Link to="/user/pr-manager">
            <Prmanage fill={`${activeTab === 2 ? "#FFFFFF" : "#8B8EA5"}`} />
          </Link>
          <Link
            to="/user/pr-manager"
            className={`${showFullSidebar ? "" : "d-none"} d-lg-inline`}
          >
            PR Manager
          </Link>
        </li>
        <li
          className={`cursor-pointer nav_item${
            activeTab === 3 ? "_active" : ""
          }`}
          onClick={() => setActiveTab(3)}
        >
          <Link to="/user/sub-account">
            <Companysvg fill={`${activeTab === 3 ? "#FFFFFF" : "#8B8EA5"}`} />
          </Link>
          <Link
            to="/user/sub-account"
            className={`${showFullSidebar ? "" : "d-none"} d-lg-inline`}
          >
            Sub-accounts
          </Link>
        </li>
        <li
          className={`cursor-pointer nav_item${
            activeTab === 4 ? "_active" : ""
          }`}
          onClick={() => setActiveTab(4)}
        >
          <Link to="/user/gallery">
            <Gallerysvg fill={`${activeTab === 4 ? "#FFFFFF" : "#8B8EA5"}`} />
          </Link>
          <Link
            to="/user/gallery"
            className={`${showFullSidebar ? "" : "d-none"} d-lg-inline`}
          >
            Gallery
          </Link>
        </li>
        <li
          className={`cursor-pointer nav_item${
            activeTab === 5 ? "_active" : ""
          }`}
          onClick={() => setActiveTab(5)}
        >
          <Link to="/user/payment">
            <PaymentHistorysvg
              fill={`${activeTab === 5 ? "#FFFFFF" : "#8B8EA5"}`}
            />
          </Link>
          <Link
            to="/user/payment"
            className={`${showFullSidebar ? "" : "d-none"} d-lg-inline`}
          >
            Payment History
          </Link>
        </li>
        <li className={`nav_item`}>
          <div className={`nav_line`}></div>
        </li>
        <li
          className={`cursor-pointer nav_item${
            activeTab === 6 ? "_active" : ""
          }`}
          onClick={() => setActiveTab(6)}
        >
          <Link to="/user/newsroom">
            <Newsroomsvg fill={`${activeTab === 6 ? "#FFFFFF" : "#8B8EA5"}`} />
          </Link>
          <Link
            to="/user/newsroom"
            className={`${showFullSidebar ? "" : "d-none"} d-lg-inline`}
          >
            News room
          </Link>
        </li>

        <li
          className={`cursor-pointer nav_item${
            activeTab === 7 ? "_active" : ""
          }`}
          onClick={() => setActiveTab(7)}
        >
          <Link to="/user/affiliate-center">
            <Affiliatesvg fill={`${activeTab === 7 ? "#FFFFFF" : "#8B8EA5"}`} />
          </Link>
          <Link
            to="/user/affiliate-center"
            className={`${showFullSidebar ? "" : "d-none"} d-lg-inline`}
          >
            Affiliate Center
          </Link>
        </li>
        <li
          className={`cursor-pointer nav_item${
            activeTab === 8 ? "_active" : ""
          }`}
          onClick={() => setActiveTab(8)}
        >
          <Link to="/user/pricing">
            <Pricingsvg fill={`${activeTab === 8 ? "#FFFFFF" : "#8B8EA5"}`} />
          </Link>
          <Link
            to="/user/pricing"
            className={`${showFullSidebar ? "" : "d-none"} d-lg-inline`}
          >
            Pricing
          </Link>
        </li>
        <li
          className={`cursor-pointer nav_item${
            activeTab === 9 ? "_active" : ""
          }`}
          onClick={() => setActiveTab(9)}
        >
          <Link to="/user/support">
            <Supportsvg fill={`${activeTab === 9 ? "#FFFFFF" : "#8B8EA5"}`} />
          </Link>
          <Link
            to="/user/support"
            className={`${showFullSidebar ? "" : "d-none"} d-lg-inline`}
          >
            Support
          </Link>
        </li>
        <li
          className={`cursor-pointer nav_item${
            activeTab === 10 ? "_active" : ""
          }`}
          onClick={() => setActiveTab(10)}
        >
          <Link to="/user/contact">
            <Conatactussvg
              fill={`${activeTab === 10 ? "#FFFFFF" : "#8B8EA5"}`}
            />
          </Link>
          <Link
            to="/user/contact"
            className={`${showFullSidebar ? "" : "d-none"} d-lg-inline`}
          >
            Contact Us
          </Link>
        </li>
      </ul>
      <div
        className={`help_box ${showFullSidebar ? "" : "d-none"} d-lg-block `}
        style={{
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", left: "0", bottom: "0" }}>
          <img src="/build/img/lines.svg" alt="" />
        </div>
        <p className={`help_first`}>Need help using the product?</p>
        <p className={`help_second`}>
          Click on the button below to get detailed informational videos on
          youtube.
        </p>
        <div className="d-flex justify-content-center">
          <button className={`learn_btn`}>
            <span>Learn More</span>
          </button>
        </div>
      </div>
    </sidebar>
  );
}
