"use client";
import { Table, Pagination, Dropdown, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import "./PRmanager.css";
import Cards from "./Cards";
import { Link } from "react-router-dom";
import Instance from "../../Utils/Axios";

export default function Published() {
  const [show, setShow] = useState(false);
  const [showViewDetailsModal, setShowViewDetailsModal] = useState(false);
  const [prPublish, setPrPublish] = useState([]);
  const [error, setError] = useState("");
  const [companyDetails, setCompanyDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseViewDetailsModal = () => setShowViewDetailsModal(false);
  const handleShowViewDetailsModal = () => setShowViewDetailsModal(true);

  useEffect(() => {
    const fetchPRList = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/pressrelease/list", {
        tokenId: token,
        status: "PUBLISHED",
      })
        .then((response) => {
          const data = response.data?.message || [];
          setPrPublish(Array.isArray(data) ? data : []);
          setError("");
          console.log("check stats", response.data?.message);
        })
        .catch((error) => {
          setError("Failed to fetch the details");
          console.error("failed to fetch card details", error);
        });
    };
    fetchPRList();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (rows) => {
    setRowsPerPage(rows);
    setCurrentPage(1);
  };

  const indexOfLastPR = currentPage * rowsPerPage;
  const indexOfFirstPR = indexOfLastPR - rowsPerPage;
  const currentPR = prPublish.slice(indexOfFirstPR, indexOfLastPR);

  const totalPages = Math.ceil(prPublish.length / rowsPerPage);

  const sortedPR = [...prPublish].sort((a, b) => {
    if (sortConfig.key) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIconClass = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "rotate-180" : "rotate-0";
    }
    return "rotate-0";
  };

  const currentSortedPR = sortedPR.slice(indexOfFirstPR, indexOfLastPR);

  return (
    <>
      <Cards />
      <div className="my_press">
        <h4 className="my_press_text">My Press Releases</h4>
        <div className="my_press_btns">
          <Link to="/user/pr-manager">
            <button className="my_view_btn">View all</button>
          </Link>
          <Link to="/user/submit-pr">
            <button className="my_add_btn">
              <img
                className={"pr_add_img mb-0"}
                src="/build/img/Icons/Vector.png"
                alt="zex pr wire Logo"
                width={13}
                height={13}
                priority
              />
              Add new
            </button>
          </Link>
        </div>
      </div>
      <div className="my_pr_tables">
        <Table hover className="mb-0">
          <thead className="pr_tables_head">
            <tr>
              <th
                onClick={() => {
                  handleSort("packageName");
                }}
                style={{ cursor: "pointer" }}
              >
                PACKAGE NAME
                <img
                  className={`pr_add_img mb-0 ${getSortIconClass(
                    "packageName"
                  )}`}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
              <th
                onClick={() => {
                  handleSort("title");
                }}
                style={{ cursor: "pointer" }}
              >
                TITLE
                <img
                  className={`pr_add_img mb-0 ${getSortIconClass("title")}`}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
              <th
                onClick={() => {
                  handleSort("companyName");
                }}
                style={{ cursor: "pointer" }}
              >
                COMPANY NAME
                <img
                  className={`pr_add_img mb-0 ${getSortIconClass(
                    "companyName"
                  )}`}
                  src="/build/img/down_arrow.svg"
                  alt="zex pr wire Logo"
                  width={26}
                  height={18}
                  priority
                />
              </th>
              <th>STATUS</th>
              <th>ACTIONS</th>
              <th>NOTE</th>
              <th>REPORT</th>
            </tr>
          </thead>
          <tbody className="pr_tables_body">
            {currentSortedPR.length === 0 ? (
              <tr>
                <td colSpan="7" className="text-center">
                  NO DATA AVAILABLE
                </td>
              </tr>
            ) : (
              currentSortedPR.map((list, index) => (
                <tr key={index}>
                  <td>{list.packageName}</td>
                  <td>{list.title}</td>
                  <td>{list.companyName}</td>
                  <td>
                    {" "}
                    <div className="published_btn">{list.status}</div>
                  </td>
                  <td>
                    <>
                      <Link to={`/user/pr-manager/edit/${list.id}`}>
                        <img
                          className={"pr_add_img mb-0"}
                          src="/build/img/Icons/Edit.svg"
                          alt="zex pr wire Logo"
                          width={24}
                          height={24}
                          style={{ cursor: "pointer" }}
                          priority
                        />
                      </Link>
                      <img
                        className={"pr_add_img mb-0"}
                        src="/build/img/Icons/Delete.svg"
                        alt="zex pr wire Logo"
                        width={24}
                        height={24}
                        priority
                      />
                    </>
                  </td>
                  <td>
                    <div
                      className="pr_note"
                      onClick={handleShowViewDetailsModal}
                    >
                      View Detail
                    </div>
                  </td>
                  <td>
                    <div className="in_process_btn"> In Process</div>
                  </td>

                  <Modal
                    show={showViewDetailsModal}
                    onHide={handleCloseViewDetailsModal}
                    centered
                  >
                    <Modal.Body>
                      <div>
                        <h4 className="pr_modal_head">View Details</h4>
                      </div>
                      <div>
                        <h6 className="pr_modal_note">Reason for Rejection</h6>
                        <p className="pr_modal_content">{list.addNote}</p>
                        <div className="pr_modal_date">
                          <span>{list.createdOn}</span>
                        </div>
                      </div>
                      <div className="pr_modal_btn">
                        <Button
                          className="modal_cancel_btn"
                          variant="secondary"
                          onClick={handleCloseViewDetailsModal}
                        >
                          Close
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </tr>
              ))
            )}
          </tbody>
          <caption>
            <div className="pr_rows_table">
              <div className="pr_dropdown">
                <h4 className="mb-0">Rows per page</h4>
                <Dropdown className="pr_rows_dropdown">
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    {rowsPerPage}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {[5, 10, 15, 20].map((rows) => (
                      <Dropdown.Item
                        key={rows}
                        onClick={() => handleRowsPerPageChange(rows)}
                      >
                        {rows}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="pr_pagination">
                <Pagination className="mb-0">
                  <Pagination.First onClick={() => handlePageChange(1)} />
                  <Pagination.Prev
                    onClick={() =>
                      handlePageChange(Math.max(1, currentPage - 1))
                    }
                  />
                  {[...Array(totalPages).keys()].map((page) => (
                    <Pagination.Item
                      key={page + 1}
                      active={page + 1 === currentPage}
                      onClick={() => handlePageChange(page + 1)}
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      handlePageChange(Math.min(totalPages, currentPage + 1))
                    }
                  />
                  <Pagination.Last
                    onClick={() => handlePageChange(totalPages)}
                  />
                </Pagination>
              </div>
            </div>
          </caption>
        </Table>
      </div>
    </>
  );
}
