import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useNavigate } from "react-router-dom";
import "./SupportLayout.css";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

export default function NewTicket() {
  const [ticketData, setTicketData] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/support/create", {
      tokenId: token,
      subject: ticketData.subject,
      description: ticketData.description,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          showToast("Ticket created succesfully", "success", "success");
          setTimeout(() => {
            navigate("/user/support");
          }, 1000);
        } else {
          console.error("Error:", response.data.resMessage);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTicketData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="new_ticket_layout">
        {/* <Row>
          <Col className="custom_col_new_ticket mb-4">
            <Form.Label>Contact Name</Form.Label>
            <Form.Control placeholder=" Enter text here" />
          </Col>
        </Row>
        <Row>
          <Col xs="6" className="custom_col_new_ticket mb-4">
            <Form.Label>Email</Form.Label>
            <Form.Control placeholder="Enter text here" />
          </Col>
          <Col xs="2" className="custom_col_new_ticket mb-4">
            <Form.Label>Country Code</Form.Label>
            <Form.Control placeholder="IND | +91" />
          </Col>
          <Col xs="4" className="custom_col_new_ticket mb-4">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control placeholder="Enter text here" />
          </Col>
        </Row> */}
        <Row>
          <Col className="custom_col_new_ticket mb-4">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              placeholder="Enter text here"
              name="subject"
              value={ticketData.subject}
              onChange={handleChange}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col className="custom_col_new_ticket mb-4">
            <Form.Label>How may we help you?</Form.Label>
            <Form.Select>
              <option>Select from dropdown</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Col>
        </Row> */}
        <Row>
          <Col className="custom_col_new_ticket mb-4">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Comment Box"
              name="description"
              value={ticketData.description}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <div className="ticket_account_buttons">
          <Link to="/user/support">
            <button className="ticket_cancel_btn mb-4">Cancel</button>
          </Link>
          <button className="ticket_save_btn mb-4" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
