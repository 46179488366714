import PricingHeader from "../../components/Layouts/PricingHeader";
import Payment from "../../components/BuyCredit/Payment";
import BottomBar from "../../components/Layouts/BottomBar";
import PricingFooter from "../../components/Layouts/PricingFooter";
import "./Credit.css";

const CreditPayment = () => {
  return (
    <>
      <div className="billing_page_layout">
        <PricingHeader />
        <Payment />
        <BottomBar />
        <PricingFooter />
      </div>
    </>
  );
};

export default CreditPayment;
