import PricingHeader from "../../components/Layouts/PricingHeader";
import NewsRoomPage from "../../components/Newsroom/NewsRoom";
import BottomBar from "../../components/Layouts/BottomBar";
import PricingFooter from "../../components/Layouts/PricingFooter";
import "./Newsroom.css";
const NewsRoom = () => {
  return (
    <>
      <div className="newsroom_page">
        <PricingHeader />
        <NewsRoomPage />
        <BottomBar />
        <PricingFooter />
      </div>
    </>
  );
};

export default NewsRoom;
