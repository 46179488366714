import "./Login.css";
import Header from "../../components/Layouts/Header";
import Layout from "../../components/Layouts/Layout";
import Footer from "../../components/Layouts/Footer";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../Utils/index";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const handleViewPassword = () => {
    setVisible(!visible);
  };

  const loginUser = (e) => {
    e.preventDefault();
    Instance.post("/user/login", {
      email: username,
      password: password,
      skipCSRF: 1,
      skipLoginOTP: 1,
    })
      .then((response) => {
        localStorage.setItem("tokenId", response.data.tokenId);
        showToast("Login successful!", "success", "success");
        setTimeout(() => {
          navigate("/user/dashboard");
        }, 2000);
        console.log("Login successful:", response);
      })
      .catch((error) => {
        // setError(error.response.data.resMessage);
        //console.error("Login error:", error);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("tokenId");
    if (token) {
      showToast("You are already logged in!", "success", "success");
      setTimeout(() => {
        navigate("/user/dashboard");
      }, 1500);
    }
  }, [navigate]);

  return (
    <>
      <div className="full_screen">
        <Header />
        <div className="content">
          <Layout>
            <>
              <div className={`col-6 signin_container mx-auto`}>
                <h1>Sign In</h1>
                <Form onSubmit={loginUser}>
                  <div className={`signin_wrapper`}>
                    <Form.Control
                      type="email"
                      placeholder="Username or Email Id"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <Form.Control
                      size="password"
                      type={visible ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      // onKeyPress={handleKeyPress}
                    />
                    <img
                      className={` logo visible `}
                      src="/build/eye.svg"
                      alt="zex pr wire Logo"
                      width={24}
                      height={24}
                      priority
                      onClick={handleViewPassword}
                      style={{ cursor: "pointer" }}
                    />
                    <Link to="/forgot" className={`mb-0 forgot`}>
                      Forgot Password?
                    </Link>

                    <button type="submit" className={` signin_btn`}>
                      Sign in
                    </button>
                    {error && <p className="text-danger mt-2">{error}</p>}
                    <p className={`mb-0 text-center  or`}>OR</p>
                    <div className={`d-flex justify-content-between`}>
                      <div className={`d-flex gap-2 auth_btn`}>
                        <img
                          className={"logo"}
                          src="/build/Google_Logo.svg"
                          alt="zex pr wire Logo"
                          width={24}
                          height={24}
                          priority
                        />
                        <p>Sign in with Google</p>
                      </div>
                      <div className={`d-flex gap-2 auth_btn auth_btn_two`}>
                        <img
                          className={"logo"}
                          src="/build/Google_Logo.svg"
                          alt="zex pr wire Logo"
                          width={24}
                          height={24}
                          priority
                        />
                        <p>Sign in with Apple</p>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          </Layout>
          <Footer />
        </div>
      </div>
    </>
  );
}
