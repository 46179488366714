import { Col, Row } from "react-bootstrap";
import "./NewsroomPage.css";
import Pagination from "react-bootstrap/Pagination";

const NewsRoomPage = () => {
  return (
    <>
      <div className="newsroom_layout">
        <div className="newsroom_details">
          <h4 className="newsroom_title">Newsroom</h4>
          <div className="newsroom_news">
            <div className="news_info">
              <h5>Citizens Commission on Human Rights Featured in Nashville</h5>
              <p>2024/07/04 10:00</p>
            </div>
            <div className="news_info">
              <h5>
                Pazusoft Makes Pazu Spotify Converter More Accessible with
                Multilingual Update
              </h5>
              <p>2024/07/03 11:56</p>
            </div>
            <div className="news_info">
              <h5>
                Pawfury Token Sale Booms Amid Declining Dogecoin and Shiba Inu
                Trading Volumes
              </h5>
              <p>2024/07/03 11:51</p>
            </div>
            <div className="news_info">
              <h5>
                Dr. Ji Han of NY Spine Care Interventional Pain Management
                Shares The Critical Importance of Professional Intervention in
                Spinal Health Management
              </h5>
              <p>2024/07/03 00:39</p>
            </div>
            <div className="news_info">
              <h5>Citizens Commission on Human Rights Featured in Nashville</h5>
              <p>2024/07/04 10:00</p>
            </div>
            <div className="news_info">
              <h5>Citizens Commission on Human Rights Featured in Nashville</h5>
              <p>2024/07/04 10:00</p>
            </div>
            <div className="news_info">
              <h5>Citizens Commission on Human Rights Featured in Nashville</h5>
              <p>2024/07/04 10:00</p>
            </div>
            <div className="news_info">
              <h5>Citizens Commission on Human Rights Featured in Nashville</h5>
              <p>2024/07/04 10:00</p>
            </div>
            <div className="news_info">
              <h5>Citizens Commission on Human Rights Featured in Nashville</h5>
              <p>2024/07/04 10:00</p>
            </div>
            <div className="news_info">
              <h5>Citizens Commission on Human Rights Featured in Nashville</h5>
              <p>2024/07/04 10:00</p>
            </div>
          </div>
          <Pagination style={{ marginTop: "24px" }}>
            <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item active>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Item>{3}</Pagination.Item>
            <Pagination.Next />
            <Pagination.Last />
          </Pagination>
        </div>
        <div className="pr_details">
          <h4 className="newsroom_title">Top PR</h4>
          <div className="newsroom_pr">
            <div className="pr_info">
              <h5>Real Estate in Turkey</h5>
              <p>
                Blockchain-based social network BSocial arrives with a gathering
                of distinctive features.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsRoomPage;
