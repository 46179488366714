import Header from "../../components/Layouts/Header";
import Layout from "../../components/Layouts/Layout";
import Footer from "../../components/Layouts/Footer";
import "../Login/Login.css";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

export default function SignUp() {
  const [title, setTitle] = useState("mr");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [isdCode, setIsdCode] = useState("+91");
  const [address, setAddress] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const navigate = useNavigate();

  const handleViewPassword = () => {
    setVisible(!visible);
  };
  const handleViewConfirmPwd = () => {
    setVisible2(!visible2);
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    setLoading(true);
    Instance.post("/user/createUser", {
      title,
      firstName,
      lastName,
      mobileNumber,
      email,
      country,
      isdCode,
      address,
      pwd,
      confirmPwd,
    })
      .then((response) => {
        setLoading(false);
        showToast("Registration successful!", "success", "success");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        setError("Error registering user. Please try again.");
        console.error("Error registering user:", error);
      });
  };

  const fetchCountryList = () => {
    Instance.post("/user/common/countryList", {
      status: "",
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          const filteredCountries = response.data.message.filter(
            (country) => country.countryName.trim() !== ""
          );
          setCountryList(filteredCountries);
          setError("");
        } else {
          setError("Failed to fetch countries list");
          console.error(
            "Error fetching countries list:",
            response.data.resMessage
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch countries list");
        console.error("Error fetching countries list:", error);
      });
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("tokenId");
    if (token) {
      showToast("You are already logged in!", "success", "success");
      setTimeout(() => {
        navigate("/user/dashboard");
      }, 1500);
    }
  }, [navigate]);

  return (
    <>
      <div className="full_screen">
        <Header />
        <div className="content">
          <Layout>
            <div className={`col-6 signup_container`}>
              <h1>Sign Up</h1>
              <Form onSubmit={handleSignUp}>
                <div className={`signup_wrapper`}>
                  <div className={`name_container`}>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <Form.Control
                    type="tel"
                    placeholder="Phone Number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <div className="select_container">
                    <Form.Select
                      placeholder="Select Country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="">Select Country</option>
                      {countryList.map((country) => (
                        <option
                          key={country.countryId}
                          value={country.countryName}
                        >
                          {country.countryName}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <Form.Control
                    type="email"
                    placeholder="Email Id"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className={` password_container `}>
                    <Form.Control
                      type={visible ? "text" : "password"}
                      placeholder="Password"
                      value={pwd}
                      onChange={(e) => setPwd(e.target.value)}
                    />
                    <img
                      className={` logo visible_two `}
                      src="/build/eye.svg"
                      alt="Show Password"
                      width={24}
                      height={24}
                      onClick={handleViewPassword}
                      style={{ cursor: "pointer" }}
                    />
                    <Form.Control
                      type={visible2 ? "text" : "password"}
                      placeholder="Confirm Password"
                      value={confirmPwd}
                      onChange={(e) => setConfirmPwd(e.target.value)}
                    />
                    <img
                      className={` logo visible_three `}
                      src="/build/eye.svg"
                      alt="Show Password"
                      width={24}
                      height={24}
                      onClick={handleViewConfirmPwd}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {successMessage && (
                    <p className="text-success">{successMessage}</p>
                  )}
                  {error && <p className="text-danger">{error}</p>}
                  <button
                    type="submit"
                    className={`signin_btn`}
                    disabled={loading}
                  >
                    {loading ? "Processing..." : "Sign Up"}
                  </button>
                  <p className={`mb-0 text-center  or`}>OR</p>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2 auth_btn">
                      <img
                        className={"logo"}
                        src="/build/Google_Logo.svg"
                        alt="Google Logo"
                        width={24}
                        height={24}
                      />
                      <p>Sign in with Google</p>
                    </div>
                    <div className="d-flex gap-2 auth_btn auth_btn_two">
                      <img
                        className={"logo"}
                        src="/build/Google_Logo.svg"
                        alt="Apple Logo"
                        width={24}
                        height={24}
                      />
                      <p>Sign in with Apple</p>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Layout>
          <Footer />
        </div>
      </div>
    </>
  );
}
