import "./Pricing.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils";

const Payment = () => {
  const [profileDetails, setProfileDetails] = useState([]);
  const [packageDetails, setPackageDetails] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const { superPNR } = location.state;

  const fetchProfileDate = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/details", {
      tokenId: token,
    })
      .then((response) => {
        setProfileDetails(response.data.userProfile);
      })
      .catch((error) => {
        console.error("Error fetcht the profile detials", error);
      });
  };

  const fetchPackageData = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/package/details", {
      tokenId: token,
      packageId: id,
    })
      .then((response) => {
        if (response.data.resCode === "400") {
          showToast("Unable to load the information", "error", "error");
          setTimeout(() => {
            navigate("/user/dashboard");
          }, 1500);
        } else {
          setPackageDetails(response.data.message);
        }
      })
      .catch((error) => {
        console.error("error fetching the package details", error);
      });
  };

  useEffect(() => {
    fetchProfileDate();
    fetchPackageData();
  }, []);

  const handleSubmit = () => {
    const token = localStorage.getItem("tokenId");
    const PNR = localStorage.getItem("superPNR");
    Instance.post("/user/credit/purchase", {
      tokenId: token,
      superPNR: PNR,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          console.log("purchase started");
          // const PNR = response.data.superPNR;
          setTimeout(() => {
            navigate(`/user/payment_method/${id}`);
          }, 1000);
        } else {
          showToast(response.data.resMessage, "error", "error");
        }
      })
      .catch((error) => {
        console.error("cannot procced ahead!", error);
      });
  };

  return (
    <>
      <div className="credit_payment_layout">
        <div className="payment_steps">
          <div className="d-flex ">
            <div className="paymeny_step_img">
              <img src="/build/greycart.png" width={74} height={74} />
            </div>
            <div className="steps_detail">
              <h5>Browse Packages</h5>
              <p>Step 1</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="paymeny_step_img active_step p-3 ">
              <img src="/build/whitetruck.png" width={40} height={32} />
            </div>
            <div className="steps_detail">
              <h5>Billing Info</h5>
              <p>Step 2</p>
            </div>
          </div>

          <div className="d-flex ">
            <div className="paymeny_step_img ">
              <img src="/build/greypayment.png" width={74} height={74} />
            </div>
            <div className="steps_detail">
              <h5>Payment Methods</h5>
              <p>Step 3</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="paymeny_step_img">
              <img src="/build/greyaward.png" width={74} height={74} />
            </div>
            <div className="steps_detail">
              <h5>Confirmation</h5>
              <p>Step 4</p>
            </div>
          </div>
        </div>
        <div className="billing_details">
          <div className="package_details">
            <h4 className="billing_title">Choose Package</h4>
            <div className="package_name">
              <h5>{packageDetails.packageName || "n/a"}</h5>
              <p>{packageDetails.validity || ""} Days</p>
            </div>
            <div className="package_info">
              <h5>Package Information</h5>
              <p>Package Validity is :- {packageDetails.validity || ""} Days</p>
              <p>No of credits is :- 1</p>
            </div>
            <div className="package_pricing">
              <p>{packageDetails.packageName || "n/a"}</p>
              <span>$ {packageDetails.price || "0"}</span>
            </div>
            <div className="package_pricing">
              <p>Discount</p>
              <span></span>
            </div>
            <div className="pricing_total">
              <p>Total</p>
              <span>$ {packageDetails.price || "0"}</span>
            </div>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Enter a valid coupon code"
                aria-label="coupon"
                aria-describedby="basic-addon1"
                style={{ borderRight: "none" }}
              />
              <button className="coupon_apply_btn">Apply</button>
            </InputGroup>
          </div>
          <div className="shipping_details">
            <h4 className="billing_title">Shipping Details</h4>
            <Form>
              <h4>User Information</h4>

              <Row>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    placeholder=" Enter First Name"
                    name="name"
                    value={profileDetails.firstName}
                    readOnly
                  />
                </Col>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={profileDetails.lastName}
                    readOnly
                  />
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    placeholder=" Enter Country"
                    name="country"
                    value={profileDetails.country}
                    readOnly
                  />
                </Col>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    placeholder="Enter Adress"
                    name="address"
                    value={profileDetails.address}
                    readOnly
                  />
                </Col>
              </Row>
              <Row>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control
                    placeholder=" Enter Mobile Number"
                    name="mobile"
                    value={profileDetails.mobile}
                    readOnly
                  />
                </Col>
                <Col className="custom_col_sub_account mb-4">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    placeholder="Enter Email"
                    name="email"
                    value={profileDetails.email}
                    readOnly
                  />
                </Col>
              </Row>
            </Form>
          </div>
          <div className="package_buy_btns">
            <Link to="/user/pricing">
              <button className="back_btn">Go Back</button>
            </Link>
            <button className="pay_btn" onClick={handleSubmit}>
              Proceed to Pay
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
