import { Col, Row } from "react-bootstrap";
import "./Pricing.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils";

const PricingCard = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPackageDetails = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/package/getList", {
        tokenId: token,
        status: "active",
      })
        .then((response) => {
          if (response.data.resCode === "400") {
            showToast("Unable to load the page", "error", "error");
            setTimeout(() => {
              navigate("/user/dashboard");
            }, 1500);
          } else {
            const packagesData = Array.isArray(response.data.message)
              ? response.data.message
              : [];
            setPackages(packagesData);
            setLoading(false);
            console.log(response.data.message);
          }
        })
        .catch((error) => {
          console.error("Cannot fetch package details", error);
          setLoading(false);
        });
    };
    fetchPackageDetails();
  }, []);

  if (loading) return <div className="loader"></div>;

  const handleBuy = (packageId) => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/credit/buyCredits", {
      tokenId: token,
      packageId: packageId,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          const superPNR = response.data.superPNR;
          localStorage.setItem("superPNR", superPNR);
          setTimeout(() => {
            navigate(`/user/pricing/${packageId}`);
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("cannot procced ahead!", error);
      });
  };

  const rows = [];
  for (let i = 0; i < packages.length; i += 3) {
    rows.push(packages.slice(i, i + 3));
  }
  return (
    <>
      {rows.map((row, rowIndex) => (
        <div className="d-flex justify-content-center mb-5">
          <Row key={rowIndex} className="pricing_cards_row">
            {row.map((pkg, colIndex) => (
              <Col key={pkg.packageId} xs={12} md={4} className="p-0">
                {/* <div className={`pricing_card ${pkg.popular ? "main_card" : ""}`}> */}
                <div
                  className={`pricing_card ${
                    colIndex === 1 ? "main_card" : ""
                  }`}
                >
                  <div className="pricing_card_head">
                    <h4>{pkg.name}</h4>
                  </div>
                  <div className="pricing_card_body">
                    <ul>
                      {pkg.description && Array.isArray(pkg.description) ? (
                        pkg.description.map((desc, idx) => (
                          <li key={idx}>
                            <FontAwesomeIcon
                              icon={
                                desc.checkedOption === "CHECKED"
                                  ? faCheck
                                  : faXmark
                              }
                              className="me-2"
                              style={{ color: "rgb(89, 95, 222)" }}
                            />
                            {desc.description || "No description available"}
                          </li>
                        ))
                      ) : (
                        <li>No description available</li>
                      )}
                    </ul>
                  </div>
                  <div className="price_card_amount">
                    <h2>
                      <sup>$ </sup>
                      {pkg.price}
                    </h2>

                    {/* <Link
                      to={`/pricing/${pkg.packageId}`}
                      style={{ margin: "auto" }}
                    > */}
                    <button onClick={() => handleBuy(pkg.packageId)}>
                      Buy Now
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </>

    // <>
    //   <div className="d-flex justify-content-center mb-5">
    //     <Row className="pricing_cards_row gx-0">
    //       <Col xs={12} md={4}>
    //         <div className="pricing_card">
    //           <div className="pricing_card_head">
    //             <h4> Bloomberg Press Release</h4>
    //           </div>
    //           <div className="pricing_card_body">
    //             <ul>
    //               <li>
    //                 <FontAwesomeIcon icon={faCheck} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //               <li>
    //                 <FontAwesomeIcon icon={faCheck} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //               <li>
    //                 <FontAwesomeIcon icon={faCheck} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //               <li>
    //                 <FontAwesomeIcon icon={faXmark} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //             </ul>
    //             <div className="price_card_amount">
    //               <h2>
    //                 <sup>$ </sup>1499
    //               </h2>
    //               <Link to="/pricing/1" style={{ margin: "auto" }}>
    //                 <button>Buy Now</button>
    //               </Link>
    //             </div>
    //           </div>
    //         </div>
    //       </Col>
    //       <Col xs={12} md={4}>
    //         <div className="pricing_card main_card">
    //           <div className="pricing_card_head">
    //             {/* <span className="popular_tag">Popular</span> */}
    //             <h4> Bloomberg, Business Insider, Yahoo</h4>
    //           </div>
    //           <div className="pricing_card_body">
    //             <ul>
    //               <li>
    //                 <FontAwesomeIcon icon={faCheck} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //               <li>
    //                 <FontAwesomeIcon icon={faCheck} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //               <li>
    //                 <FontAwesomeIcon icon={faCheck} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //               <li>
    //                 <FontAwesomeIcon icon={faCheck} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //             </ul>
    //             <div className="price_card_amount main_amount">
    //               <h2>
    //                 <sup>$ </sup>2249
    //               </h2>
    //               <Link to="/pricing/1" style={{ margin: "auto" }}>
    //                 <button>Buy Now</button>
    //               </Link>
    //             </div>
    //           </div>
    //         </div>
    //       </Col>
    //       <Col xs={12} md={4}>
    //         <div className="pricing_card">
    //           <div className="pricing_card_head">
    //             <h4> Business Insider with Yahoo Finance</h4>
    //           </div>
    //           <div className="pricing_card_body">
    //             <ul>
    //               <li>
    //                 <FontAwesomeIcon icon={faCheck} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //               <li>
    //                 <FontAwesomeIcon icon={faCheck} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //               <li>
    //                 <FontAwesomeIcon icon={faCheck} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //               <li>
    //                 <FontAwesomeIcon icon={faCheck} className="check_icon" />
    //                 Press Release On Bloomberg Terminal (no live link)
    //               </li>
    //             </ul>
    //             <div className="price_card_amount">
    //               <h2>
    //                 <sup>$ </sup>749
    //               </h2>
    //               <Link to="/pricing/1" style={{ margin: "auto" }}>
    //                 <button>Buy Now</button>
    //               </Link>
    //             </div>
    //           </div>
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>
    // </>
  );
};

export default PricingCard;
