import "../Login/Login.css";
import Header from "../../components/Layouts/Header";
import Layout from "../../components/Layouts/Layout";
import Footer from "../../components/Layouts/Footer";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../Utils/index";

export default function NewPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [apiData, setApiData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // fetchOTPData();
  }, []);

  // const fetchOTPData = () => {
  //   setLoading(true);
  //   Instance.get("/user/resetPassword")
  //     .then((response) => {
  //       setApiData(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       setError("Failed to fetch OTP data. Please try again.");
  //       console.error("Error fetching OTP data:", error);
  //     });
  // };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    Instance.post("/user/resetPassword", {
      email: email,
      otp: otp,
      password: password,
    })
      .then((response) => {
        setLoading(false);
        showToast("Reset Password successful!", "success", "success");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        setError("Error resetting password. Please try again.");
        console.error("Error resetting password:", error);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("tokenId");
    if (token) {
      showToast("You are already logged in!", "success", "success");
      setTimeout(() => {
        navigate("/user/dashboard");
      }, 1500);
    }
  }, [navigate]);

  return (
    <>
      <div className="full_screen">
        <Header />
        <div className="content">
          <Layout>
            <>
              <div className={`col-6 new_pass_container`}>
                <h1>Setup New Password</h1>
                <Form onSubmit={handleResetPassword}>
                  <div className={`new_pass_wrapper`}>
                    <Form.Control
                      type="Email"
                      placeholder="Username or Email Id"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                    <Form.Control
                      size="password"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Form.Control
                      size="password"
                      type="password"
                      placeholder="Confirm New Password"
                    />
                    {successMessage && (
                      <p className="text-success">{successMessage}</p>
                    )}
                    {error && <p className="text-danger">{error}</p>}
                    <button
                      className={`signin_btn`}
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Processing" : "Reset Password"}
                    </button>
                    <p className={`mb-0 text-center  or`}>OR</p>
                    <div className={`d-flex justify-content-between`}>
                      <div className={`d-flex gap-2 auth_btn`}>
                        <img
                          className={"logo"}
                          src="/build/Google_Logo.svg"
                          alt="zex pr wire Logo"
                          width={24}
                          height={24}
                          priority
                        />
                        <p>Sign in with Google</p>
                      </div>
                      <div className={`d-flex gap-2 auth_btn auth_btn_two`}>
                        <img
                          className={"logo"}
                          src="/build/Google_Logo.svg"
                          alt="zex pr wire Logo"
                          width={24}
                          height={24}
                          priority
                        />
                        <p>Sign in with Apple</p>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          </Layout>
          <Footer />
        </div>
      </div>
      x
    </>
  );
}
